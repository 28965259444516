import React from 'react';
import ReactJson from 'react-json-view';

import type { FunctionComponent } from 'react';
import type { LogData } from '../../../interfaces';

// const DataFormMap = React.lazy(() => import('./LogDataFormMap'));

interface LogDataFormProps {
  data: LogData;
}

const LogDataForm: FunctionComponent<LogDataFormProps> = ({ data }) => {
  const { ...json } = data;
  return (
    <>
      {/* {geom ? (
        <Suspense fallback={<div>loading map...</div>}>
          <DataFormMap geom={geom} />
        </Suspense>
      ) : (
        ''
      )} */}
      <ReactJson src={json} collapsed={true}></ReactJson>
    </>
  );
};

export default LogDataForm;
