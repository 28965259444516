import React, { useState } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';

import authStore from '../store/AuthStore';

const RequireAuth = () => {
  const [auth] = useState(() => authStore);
  const location = useLocation();

  return auth.user ? (
    <Outlet />
  ) : (
    <Navigate
      to={{ pathname: '/login', search: `?next=${location.pathname}` }}
      replace
      state={{ from: location }}
    />
  );
};

export default RequireAuth;
