/* eslint-disable @typescript-eslint/no-explicit-any */
export function getProperty(propertyName: string, object: any) {
  const parts = propertyName.split('.');
  const length = parts.length;
  let i;
  let property: any = object;

  for (i = 0; i < length; i++) {
    property = property[parts[i]];
  }

  return property;
}
