import { makeAutoObservable, runInAction } from 'mobx';

import authStore from './AuthStore';
import { getAuthHeaders } from '../utils/getAuthHeaders';
import type { Log, PaginateResponse } from '../../../interfaces';
import type { FetchListOptions as FetchLogItemsOptions } from '../interfaces';

class LogStore {
  listLoading = true;
  oneLoading = false;

  total = 0;
  logs: Log[] = [];
  errors?: unknown[] = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  clean() {
    this.total = 0;
    this.logs = [];
    this.errors = undefined;
  }

  async updateList(options: FetchLogItemsOptions) {
    this.listLoading = true;
    this.errors = undefined;
    try {
      const logs = await this.fetchItems(options);
      runInAction(() => {
        this.total = logs.total;
        this.logs = logs.data;
      });
    } catch (err) {
      this.handleError(err);
    } finally {
      runInAction(() => {
        this.listLoading = false;
      });
    }
  }

  async fetchItems({
    clientId,
    skip,
    take,
    signal,
  }: FetchLogItemsOptions): Promise<PaginateResponse<Log[]>> {
    const params = new URLSearchParams({
      clientId,
      take: String(take),
      skip: String(skip),
    }).toString();
    const resp = await fetch('/api/log?' + params, {
      signal,
      ...getAuthHeaders(authStore.token),
    });
    const logs = await resp.json();
    return logs;
  }

  private handleError(err: unknown) {
    runInAction(() => {
      this.errors = [err];
      throw err;
    });
  }
}

export default new LogStore();
