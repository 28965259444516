import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { full } from '@nextgis/utils';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Button, Modal, Table, Tooltip } from 'antd';
import { msToTime } from '../utils/msToTime';
import logStore from '../store/LogStore';
import LogDataForm from './LogDataForm';

import { ColumnsType, ColumnType } from 'antd/lib/table';
import type { Log, LogData, Service } from '../../../interfaces';
import { getProperty } from '../utils/getProperty';

interface LogTableOptions {
  clientId: string;
  service: Service;
}
let abortController: AbortController | null = null;

const LogTable = observer(({ clientId, service }: LogTableOptions) => {
  const [log] = useState(() => logStore);

  const navigate = useNavigate();
  const location = useLocation();

  const q = new URLSearchParams(location.search);
  // const defSort = q.get('sort') || '';
  const defPage = q.get('page') && Number(q.get('page'));
  const defPageSize = q.get('pageSize') && Number(q.get('pageSize'));

  const [page, setPage] = useState(defPage || 1);
  const [pageSize, setPageSize] = useState(defPageSize || 100);
  // const [sort, setSort] = useState(defSort);
  // const [search, setSearch] = useState(q.get('search') || '');
  const [loading, setLoading] = useState(false);

  const [modalData, setModalData] = useState<LogData | null>(null);
  const isModalVisible = useMemo(() => !!modalData, [modalData]);

  const showData = (log: Log) => {
    if (log.data) {
      setModalData({ ...log.data });
    }
  };
  const permanentColumns: ColumnsType<Log> = [
    {
      title: 'logLevel',
      dataIndex: 'logLevel',
      key: 'logLevel',
    },
    {
      title: 'date',

      key: 'date',
      render: (val, record) => {
        return new Date(record.timestamp).toLocaleDateString();
      },
    },
    {
      title: 'time',

      key: 'time',
      render: (val, record) => {
        return new Date(record.timestamp).toLocaleTimeString();
      },
    },
    {
      title: 'message',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'duration',
      dataIndex: 'duration',
      key: 'duration',
      render: (val) => {
        return val ? msToTime(Number(val)) : '-';
      },
    },
    {
      title: 'IP',
      dataIndex: 'fromIp',
      key: 'fromIp',
      render: (text, record) => (
        <Tooltip
          title={
            record.geoIp
              ? `${[record.geoIp.country, record.geoIp.region]
                  .filter(Boolean)
                  .join(' | ')}`
              : ''
          }
        >
          <span>{record.fromIp}</span>
        </Tooltip>
      ),
    },
    {
      dataIndex: 'data',
      key: 'data',
      render: (text, record) => (
        <Button
          disabled={!record.data}
          type="link"
          onClick={() => showData(record)}
        >
          Show data
        </Button>
      ),
    },
  ];
  const columns: ColumnsType<Log> = useMemo(() => {
    const displayFields = service?.displayFields;
    if (!displayFields) {
      return permanentColumns;
    }
    const cols: ColumnsType<Log> = [];
    for (const f of displayFields) {
      const permanent = permanentColumns.find((x) => x.key === f);
      if (permanent) {
        cols.push(permanent);
      } else {
        const col: ColumnType<Log> = {
          title: f,
          key: f,
          dataIndex: 'data.' + f,
        };
        cols.push(col);
      }
    }
    return cols;
  }, [service]);

  const dataSource = useMemo(() => {
    const displayFields = service?.displayFields;
    if (!displayFields) {
      return log.logs;
    }
    const logs: Log[] = [];
    for (const l of log.logs) {
      const data = { ...l.data };
      if (data && Object.keys(data).length) {
        const log_ = { ...l };
        for (const f of displayFields) {
          const prop = getProperty(f, data);
          if (prop) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (log_ as any)['data.' + f] = prop;
          }
        }
        logs.push(log_);
      } else {
        logs.push(l);
      }
    }
    return logs;
  }, [log.logs]);

  useEffect(() => {
    const toParams: Record<string, unknown> = {
      page,
      pageSize,
      // search: search,
      // sort: sort,
    };
    // const filters_ = Object.entries(filters);
    // for (const [k, v] of filters_) {
    //   toParams[`${QUERY_FILTER_PREFIX}${k}`] = v;
    // }
    const params = new URLSearchParams(
      Object.fromEntries(
        Object.entries(toParams)
          .filter(([, v]) => full(v))
          .map(([k, v]) => [k, String(v)]),
      ),
    );
    navigate({ pathname: location.pathname, search: params.toString() });
    load();
  }, [page, pageSize]);

  useEffect(() => {
    return () => {
      log.clean();
    };
  }, []);

  const load = useCallback(async () => {
    abort();
    abortController = new AbortController();
    const take = pageSize;
    const skip = (page - 1) * take;
    try {
      setLoading(true);
      log.updateList({ clientId, skip, take });
    } catch (er) {
      console.log(er);
    } finally {
      setLoading(false);
    }
  }, [page, pageSize]);

  const abort = () => {
    if (abortController) {
      abortController.abort();
    }
    abortController = null;
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        rowKey="id"
        size="small"
        pagination={{
          current: page,
          pageSize,
          total: log.total,
          onChange: (page, pageSize) => {
            setPageSize && pageSize !== undefined && setPageSize(pageSize);
            setPage && page && setPage(page);
          },
          position: ['bottomCenter'],
        }}
      />
      <Modal
        title="Basic Modal"
        visible={isModalVisible}
        footer={null}
        onCancel={() => setModalData(null)}
      >
        {modalData ? <LogDataForm data={modalData}></LogDataForm> : ''}
      </Modal>
    </>
  );
});

export default LogTable;
