import React, { useState } from 'react';
import {
  Route,
  Routes,
  NavLink,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Button, Col, ConfigProvider, Layout, Menu, Row } from 'antd';
import { Content, Header } from 'antd/lib/layout/layout';
// import ruRu from 'antd/lib/locale/ru_RU';
import enUs from 'antd/lib/locale/en_US';

import authStore from './store/AuthStore';
import Login from './components/Login';
import Logout from './components/Logout';
import ServiceItems from './components/ServiceItems';
import ServiceForm from './components/ServiceForm';
import LogItemsPage from './components/LogItemsPage';
import RequireAuth from './components/RequireAuth';

const App = observer(() => {
  const [auth] = useState(() => authStore);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  if (auth.initialLoading) {
    return <>Loading...</>;
  }

  return (
    <ConfigProvider locale={enUs}>
      <Layout>
        <Header
          style={{
            position: 'fixed',
            zIndex: 10,
            width: '100%',
            background: '#fff',
          }}
        >
          <Row>
            <Col flex="auto">
              <Menu mode="horizontal" selectedKeys={[pathname]}>
                <Menu.Item key="/">
                  <NavLink to="/">Services</NavLink>
                </Menu.Item>
              </Menu>
            </Col>
            <Col>
              {auth.user ? (
                <Button onClick={() => auth.logout()} type="primary">
                  Logout
                </Button>
              ) : (
                <Button onClick={() => navigate('/login')} type="primary">
                  Login
                </Button>
              )}
            </Col>
          </Row>
        </Header>
        <Content style={{ margin: '10px 10px', marginTop: 64 }}>
          <Routes>
            <Route path="/login" element={<Login />}></Route>
            <Route element={<RequireAuth />}>
              <Route
                path="/"
                element={<Navigate to="/service" replace />}
              ></Route>
              <Route path="/service" element={<ServiceItems />}></Route>
              <Route path="/service/create" element={<ServiceForm />}></Route>
              <Route path="/service/edit/:id" element={<ServiceForm />}></Route>
              <Route
                path="/service/:clientId/log"
                element={<LogItemsPage />}
              ></Route>
              <Route path="/logout" element={<Logout />}></Route>
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Content>
      </Layout>
    </ConfigProvider>
  );
});

export default App;
