import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

import authStore from '../store/AuthStore';

const Logout = observer(() => {
  const [auth] = useState(() => authStore);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.user) {
      navigate('/');
    }
  }, [auth.user]);

  const logout = () => {
    auth.logout();
  };

  return (
    <>
      <Button onClick={logout}>Logout</Button>
    </>
  );
});

export default Logout;
