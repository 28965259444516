import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Button, Popconfirm, Space, Table } from 'antd';
import serviceStore from '../store/ServiceStore';

import type { ColumnsType } from 'antd/lib/table';
import type { Service } from '../../../interfaces';
import { AllowedTags } from './AllowedTags';

const ServiceItems = () => {
  const navigate = useNavigate();

  const goToCreatePage = () => {
    navigate('/service/create');
  };
  const goToEditPage = (id: string | number) => {
    navigate('/service/edit/' + id);
  };
  const goToLogPage = (clientId: string) => {
    navigate(`/service/${clientId}/log/`);
  };

  const Services = observer(() => {
    const [service] = useState(() => serviceStore);

    const columns: ColumnsType<Service> = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'ClientId',
        dataIndex: 'clientId',
        key: 'clientId',
      },
      {
        title: 'Allowed Hosts',
        dataIndex: 'allowedHosts',
        key: 'allowedHosts',
        render: (items) => AllowedTags({ items }),
      },
      {
        title: 'Allowed IPs',
        dataIndex: 'allowedIps',
        key: 'allowedIps',
        render: (items) => AllowedTags({ items }),
      },
      {
        key: 'action',
        render: (text, record) => (
          <Space size="middle">
            <Button type="link" onClick={() => goToEditPage(record.id)}>
              Edit
            </Button>
            <Button type="link" onClick={() => goToLogPage(record.clientId)}>
              Show logs
            </Button>
            <Popconfirm
              title="Are you sure to delete this service?"
              onConfirm={() => serviceStore.delete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link" danger>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];

    useEffect(() => {
      service.fetchList();
    }, []);
    return (
      <Table columns={columns} dataSource={service.services} rowKey="id" />
    );
  });

  return (
    <>
      <Services />

      <Button onClick={goToCreatePage}>Create</Button>
    </>
  );
};

export default ServiceItems;
