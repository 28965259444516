import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import logStore from '../store/LogStore';
import serviceStore from '../store/ServiceStore';
import LogTable from './LogTable';

import type { Service } from '../../../interfaces';

let abortController: AbortController | null = null;

const LogItemsPage = observer(() => {
  const params = useParams();
  const clientId = params.clientId;
  if (!clientId) {
    return <>ClientID not defined</>;
  }
  const [exportLoading, setExportLoading] = useState(false);
  const [service, setService] = useState<Service>();
  const makeExport = async () => {
    setExportLoading(true);
    abort();
    abortController = new AbortController();
    try {
      const items = await logStore.fetchItems({
        clientId,
        take: 10000,
        skip: 0,
        signal: abortController.signal,
      });

      const dataRows = items.data.map((x) => {
        const {
          id,
          // geoIp,
          fromIp,
          message,
          duration,
          logLevel,
          // sessionId,
          timestamp,
          operationId,
        } = x;
        return [
          id,
          timestamp,
          logLevel,
          message,
          operationId,
          // sessionId,
          // geoIp,
          fromIp,
          duration,
        ];
      });

      const rows = [
        [
          'id',
          'timestamp',
          'logLevel',
          'message',
          'operationId',
          // 'sessionId',
          // 'geoIp',
          'fromIp',
          'duration',
        ],
        ...dataRows,
      ];

      const csv = rows.map((e) => e.join(',')).join('\r\n');

      const myBlob = new Blob([csv], { type: 'text/csv' });

      const url = window.URL.createObjectURL(myBlob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `${service?.name}.csv`;

      anchor.click();
      window.URL.revokeObjectURL(url);
      anchor.remove();
    } catch (er) {
      console.log(er);
    } finally {
      setExportLoading(false);
    }
  };

  useEffect(() => {
    setService(undefined);
    serviceStore.fetchOne(clientId).then((serviceItem) => {
      setService(serviceItem);
    });
    return abort;
  }, [clientId]);

  const abort = () => {
    if (abortController) {
      abortController.abort();
    }
    abortController = null;
    serviceStore.clean();
    setService(undefined);
  };

  return (
    <div>
      {service ? (
        <>
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <Button loading={exportLoading} onClick={makeExport}>
              Export
            </Button>
          </div>
          <LogTable service={service} clientId={clientId}></LogTable>
        </>
      ) : (
        'Loading...'
      )}
    </div>
  );
});

export default LogItemsPage;
