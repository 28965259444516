import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  Form,
  Space,
  Popconfirm,
  Select,
  Tag,
  message,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import serviceStore from '../store/ServiceStore';
import { validateHosts } from '../utils/validateHosts';
import { validateIPs } from '../utils/validateIPs';

import type { FunctionComponent } from 'react';
import type {
  Service,
  ServicePost,
  PermanentDisplayField,
} from '../../../interfaces';
import type { Rule } from 'antd/lib/form';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

interface ServiceFormProps {
  id?: number;
}

const ServiceForm: FunctionComponent<ServiceFormProps> = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<Partial<Service> | null>();
  const editMode = params.id !== undefined;

  useEffect(() => {
    setInitialValues(null);
    if (editMode) {
      serviceStore.fetchOne(Number(params.id)).then((service) => {
        if (!service.displayFields) {
          service.displayFields = [];
        }
        setInitialValues(service);
      });
    } else {
      setInitialValues({
        displayFields: [
          'logLevel',
          'date',
          'time',
          'message',
          'duration',
          'fromIp',
          'data',
        ],
      });
    }
  }, [editMode]);

  const onFinish = async (
    credentials: Omit<ServicePost, 'allowedHosts' | 'allowedIPs'> & {
      allowedHosts?: string;
      allowedIPs?: string;
    },
  ) => {
    const { allowedHosts: hosts, allowedIPs: ips, ...options } = credentials;
    const allowedHosts = hosts
      ? Array.isArray(hosts)
        ? hosts
        : hosts.split(/\n/).filter((s) => !s.match(/^\s*$/))
      : undefined;
    const allowedIPs = ips
      ? Array.isArray(ips)
        ? ips
        : ips.split(/\n/).filter((s) => !s.match(/^\s*$/))
      : undefined;
    if (editMode) {
      serviceStore.update({
        allowedHosts,
        allowedIPs,
        name: options.name,
        id: Number(params.id),
        displayFields: options.displayFields,
      });
      message.success('Service updated');
    } else {
      const newService = await serviceStore.create({
        allowedHosts,
        allowedIPs,
        ...options,
      });
      navigate('/service/edit/' + newService.id);
      message.success('New service created');
    }
  };

  const onDelete = async () => {
    if (params.id) {
      await serviceStore.delete(Number(params.id));
      navigate('/service');
      message.success('Service deleted');
    }
  };

  const allowHostsRules: Rule[] = [
    () => ({
      validator(_, value) {
        if (value && !validateHosts(value)) {
          return Promise.reject(new Error('The value entered is not valid'));
        }
        return Promise.resolve();
      },
    }),
  ];
  const allowIPsRules: Rule[] = [
    () => ({
      validator(_, value) {
        if (value && !validateIPs(value)) {
          return Promise.reject(new Error('The value entered is not valid'));
        }
        return Promise.resolve();
      },
    }),
  ];

  const displayFields: PermanentDisplayField[] = [
    'id',
    'fromIp',
    'message',
    'logLevel',
    'duration',
    'timestamp',
    'sessionId',
    'operationId',
    'createdAt',
    'updatedAt',
    'date',
    'time',
    'data',
  ];

  function tagRender(props: CustomTagProps) {
    const { value, label, ...respProps } = props;
    const color = displayFields.includes(value) ? 'green' : 'blue';
    return (
      <Tag
        color={color}
        onMouseDown={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
        {...respProps}
      >
        {label}
      </Tag>
    );
  }

  if (!initialValues) {
    return <>Loading...</>;
  }

  return (
    <>
      <Form
        name="basic"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={initialValues || {}}
        labelCol={{ span: 2 }}
        labelAlign="left"
      >
        {editMode ? (
          <Form.Item label="Client ID" name="clientId">
            <Input readOnly />
          </Form.Item>
        ) : (
          ''
        )}
        <Form.Item
          label="Service name"
          name="name"
          rules={[{ required: true, message: 'Please input service name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Allowed hosts"
          name="allowedHosts"
          help="Add the allowed host from each new line"
          rules={allowHostsRules}
        >
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item
          label="Allowed IPs"
          name="allowedIPs"
          help="Add the allowed IP from each new line"
          rules={allowIPsRules}
        >
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item
          label="Display fields"
          name="displayFields"
          help="Choose permanent fields or add your own from 'data'"
        >
          <Select mode="tags" tagRender={tagRender} allowClear>
            {displayFields.map((x) => (
              <Select.Option key={x}>{x}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              loading={
                serviceStore.creatingInProgress ||
                serviceStore.updatingInProgress
              }
            >
              {editMode ? 'Update' : 'Save'}
            </Button>
            {editMode ? (
              <Popconfirm
                title="Are you sure to delete this service?"
                onConfirm={onDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button danger loading={serviceStore.deletingInProgress}>
                  Delete
                </Button>
              </Popconfirm>
            ) : (
              ''
            )}
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default ServiceForm;
