export function msToTime(ms: number) {
  const seconds = ms / 1000;
  const minutes = ms / (1000 * 60);
  const hours = ms / (1000 * 60 * 60);
  const days = ms / (1000 * 60 * 60 * 24);
  if (seconds < 1) return ms + 'ms';
  if (seconds < 60) return seconds.toFixed(1) + 's';
  else if (minutes < 60) return minutes.toFixed(1) + 'm';
  else if (hours < 24) return hours.toFixed(1) + 'h';
  else return days.toFixed(1) + 'd';
}
