import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Input, Form } from 'antd';

import authStore from '../store/AuthStore';
import type { Credentials } from '../../../interfaces';

const Login = observer(() => {
  const [auth] = useState(() => authStore);
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const [next] = useState<string | null>(search.get('next'));

  const onFinish = (credentials: Credentials) => {
    auth.login(credentials).catch(() => {
      console.log('login error');
    });
  };

  useEffect(() => {
    if (auth.user) {
      navigate(next ? next : '/');
    }
  }, [auth.user]);

  return (
    <>
      <Form
        name="basic"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
      </Form>
    </>
  );
});

export default Login;
