import React from 'react';
import { Tag } from 'antd';

export function AllowedTags({ items }: { items: string[] }) {
  return (
    <>
      {items &&
        items.map((host: string) => <Tag key={host}>{host.toLowerCase()}</Tag>)}
    </>
  );
}
