export function validateIPs(val: string | string[]): boolean {
  const origins = Array.isArray(val)
    ? val
    : val.split(/\r?\n/).map((x) => x.trim());

  return origins.filter(Boolean).every(validateIPaddress);
}

function validateIPaddress(ip: string): boolean {
  const expression =
    // eslint-disable-next-line max-len
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return expression.test(ip);
}
