import { makeAutoObservable, runInAction } from 'mobx';

import authStore from './AuthStore';
import { getAuthHeaders } from '../utils/getAuthHeaders';
import type { Service, ServicePost } from '../../../interfaces';

class ServiceStore {
  listLoading = true;
  oneLoading = false;
  creatingInProgress = false;
  updatingInProgress = false;
  deletingInProgress = false;
  services: Service[] = [];
  errors?: unknown[] = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  clean() {
    this.services = [];
  }

  async fetchOne(id: number | string): Promise<Service> {
    this.oneLoading = true;
    this.errors = undefined;
    try {
      const resp = await fetch('/api/service/' + id, {
        ...getAuthHeaders(authStore.token),
      });
      return (await resp.json()) as Service;
    } finally {
      runInAction(() => {
        this.oneLoading = false;
      });
    }
  }

  async fetchList() {
    this.listLoading = true;
    this.errors = undefined;
    try {
      const resp = await fetch('/api/service', {
        ...getAuthHeaders(authStore.token),
      });
      const services = await resp.json();
      runInAction(() => {
        this.services = services;
      });
    } catch (err) {
      this.handleError(err);
    } finally {
      runInAction(() => {
        this.listLoading = false;
      });
    }
  }

  async create(service: ServicePost): Promise<Service> {
    this.creatingInProgress = true;
    this.errors = undefined;
    try {
      const resp = await fetch('/api/service', {
        method: 'POST',
        body: JSON.stringify(service),
        ...getAuthHeaders(authStore.token),
      });
      if (resp.status === 500) {
        throw new Error();
      }
      const newService = await resp.json();
      runInAction(() => {
        this.services = [...this.services, newService];
      });
      return newService;
    } catch (err) {
      this.handleError(err);
      throw err;
    } finally {
      runInAction(() => {
        this.creatingInProgress = false;
      });
    }
  }

  async update(service: ServicePost) {
    this.updatingInProgress = true;
    this.errors = undefined;
    const { id, ...payload } = service;
    try {
      const resp = await fetch('/api/service/' + id, {
        method: 'PUT',
        body: JSON.stringify(payload),
        ...getAuthHeaders(authStore.token),
      });
      if (resp.status === 500) {
        throw new Error();
      }
      const updatedService = await resp.json();
      runInAction(() => {
        const services = this.services.map((x) => {
          if (x.id === id) {
            return updatedService;
          }
          return x;
        });
        this.services = services;
      });
    } catch (err) {
      this.handleError(err);
    } finally {
      runInAction(() => {
        this.updatingInProgress = false;
      });
    }
  }

  async delete(id: Service['id']) {
    this.deletingInProgress = true;
    this.errors = undefined;

    try {
      await fetch('/api/service/' + id, {
        method: 'DELETE',
        ...getAuthHeaders(authStore.token),
      });

      runInAction(() => {
        const services = this.services.filter((x) => x.id !== id);
        this.services = services;
      });
    } catch (err) {
      this.handleError(err);
    } finally {
      runInAction(() => {
        this.deletingInProgress = false;
      });
    }
  }

  private handleError(err: unknown) {
    runInAction(() => {
      this.errors = [err];
      throw err;
    });
  }
}

export default new ServiceStore();
