import Cookies from 'js-cookie';
import { makeAutoObservable, runInAction } from 'mobx';

import type { Credentials, User } from '../../../interfaces';
import { getAuthHeaders } from '../utils/getAuthHeaders';

const COOKIE_TOKEN_ID = 'JWT_TOKEN';

class AuthStore {
  isLoading = true;
  initialLoading = true;
  errors?: unknown[] = undefined;

  token: string | null = Cookies.get(COOKIE_TOKEN_ID) || null;
  user: User | null = null;

  constructor() {
    makeAutoObservable(this);
    if (this.token) {
      this.userInfo().finally(() => {
        runInAction(() => {
          this.initialLoading = false;
        });
      });
    } else {
      this.initialLoading = false;
    }
  }

  async userInfo() {
    this.isLoading = true;
    this.errors = undefined;
    try {
      const resp = await fetch('/api/user/me', {
        ...getAuthHeaders(this.token),
      });
      const user = await resp.json();
      runInAction(() => {
        this.user = user;
      });
    } catch (err) {
      Cookies.remove(COOKIE_TOKEN_ID);
      runInAction(() => {
        this.errors = [err];
        throw err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async login(auth: Credentials) {
    this.isLoading = true;
    this.errors = undefined;

    try {
      const token = await this.getToken(auth);
      Cookies.set(COOKIE_TOKEN_ID, token);
      runInAction(() => {
        if (token) {
          this.token = token;
          this.userInfo();
        }
      });
    } catch (err) {
      runInAction(() => {
        this.errors = [err];
        throw err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async logout() {
    this.isLoading = true;
    this.errors = undefined;

    Cookies.remove(COOKIE_TOKEN_ID);
    runInAction(() => {
      this.token = null;
      this.user = null;
    });
  }

  private async getToken(auth: Credentials): Promise<string> {
    const resp = await fetch('/api/auth/login', {
      method: 'POST',
      body: JSON.stringify(auth),
      ...getAuthHeaders(),
    });
    const { token } = await resp.json();
    return token;
  }
}

export default new AuthStore();
